// Example config:
// frappe.views.calendar["Event"] = {
// 	calendar_print_config: {
// 		no_title: true, // Hide title
// 		shown_filter: "status", // Show a single filter in the top left corner
// 		Planning: {
// 			... // override settings for Planning view
// 		}
// 	}
// }

function listenToPrintEvents() {
	if (frappe.flags.print_calendar_listeners_attached) {
		return;
	}

	window.addEventListener("beforeprint", beforePrint);
	window.addEventListener("afterprint", afterPrint);
	frappe.flags.print_calendar_listeners_attached = true;

	function isPrintableCalendar(route) {
		if (route[0] === "List") {
			if (["Calendar", "Planning"].includes(route[2])) {
				return true;
			}
		}
	}
	function beforePrint() {
		const route = frappe.get_route();
		if (!isPrintableCalendar(route)) {
			return;
		}

		let config = frappe.views.calendar[route[1]]?.calendar_print_config;
		if (config?.[route[2]]) {
			config = config[route[2]];
		}

		const $calendar = $("#body > div:visible").find(".fc");

		document.body.classList.add("fc-printwrapper");
		$calendar.addClass("fc--unstyled fc--printing");

		if (config?.shown_filter) {
			// Find filter in current page
			cur_page.page.page.fields_dict[config?.shown_filter].wrapper.classList.add(
				"fc__printed-control"
			);
		}

		if (config?.no_title) {
			$calendar.addClass("fc-printing__no-title");
		}
	}
	function afterPrint() {
		if (document.body.classList.contains("fc-printwrapper")) {
			document.body.classList.remove("fc-printwrapper");
			$(".fc--printing").removeClass("fc--unstyled fc--printing fc-printing__no-title");
			$(".fc__printed-control").removeClass("fc__printed-control");
		}
	}
}
listenToPrintEvents();
