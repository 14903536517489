
	frappe.templates['resource_header'] = `{% if (planning.list_view.resource == "_assign") { %}
<div class="flex align-items-start">
	<div>{{ frappe.avatar(arg.resource.id) }}</div>
	<div class="ml-2">
		<div>{{ arg.resource.title }}</div>
	</div>
</div>
{% } else { %}
<div>{{ arg.resource.title }}</div>
{% } %}`;
